/* Card container styles */
.card-container {
    margin-top: 10%;
    margin-left: 40%;
    border-radius: 24px;
    width: 280px;
    transition: 0.2s;
    flex: 0 0 25%;
    max-width: 280px;
    position: relative;
    /* min-height: 480px; */
    max-height: 440px;

    /* padding: auto; */
    display: block;
    background-color: whitesmoke;
}
  
  /* Card styles */
  .card {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  /* Photo container styles */
  .photo-container {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    border: 3px solid orange;
  }
  
  /* Photo styles */
  .photo {
    scale: 1.1;
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  /* Hover effect for the card and photo */
  .card-container:hover .card {
    transform: scale(1.1);
    /* translate: 0px -10px; */
  }

  /* .card-container:hover .photo {
    transform: scale(1.2);
  } */
  
  /* Content styles (name and socials) */
  .content {
    text-align: center;
    padding: 15px;
  }
  
  /* Social icons styles (adjust these as needed) */
  .socials {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .socials li {
    margin: 0 10px;
  }
  
  .socials a {
    color: #333;
    text-decoration: none;
    font-size: 24px;
    display: flex;
    justify-content: center;
    padding: 6px 8px 1px 8px;
    border-radius: 12px;
  }
  
  .socials a:hover {
    background: rgba(255, 220, 181, 0.72);
    border: 2px solid orange;
  }

  /* fonts */

#name {
    margin-top: 8px;
    font-size: 1.6rem;
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    margin-bottom: 12px;

}

#position {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0;
    color: rgba(0, 0, 0, 0.712);
}

@media screen and (max-width:769px){
    .card-container {
        margin-top: 10%;
        margin-left: 12%;
    }


}
  